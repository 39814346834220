import React, { Component } from 'react'
import { 
    Box,
    Grid,
    IconButton,
    Button
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search.svg';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import apiUtil from "../../api/apiUtil.jsx";
import authApi from "../../api/api.jsx";
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import HtmlParser from 'react-html-parser';
import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import GroupButton from '../../components/GroupButton/GroupButton.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';

class RequestSMS extends Component {
  static contextType = AuthContext;
  companySearch = '';
      constructor(props) {
        super(props)
        this.refTable = React.createRef();
        this.refTableSearch = React.createRef();
    
        this.state = {
            activeInactiveItem : 0,
            data :  [],
            data_active :  [],
            data_disable :  [],
            company_id: 0,
            page : 1,
            
            selectedRow: [],
            total_active : 0,
            bulk_delete_id: '',
            company_id: '',
            total_disable : 0,
            limit : 20,
            total: 0,
            historyLimit: 20,
            historyPage: 1,
            historyPageSize: 10,
            openPreviewDialog: false,
            openPreviewDialogItem: null,
            oldOpenPreviewDialogItem: null,
            openPreviewDialogItemName: [],
            openPreviewDialogTotal: 0,

            searchData: '',
            dataPending: '',
            dataApproved: '',
            dataTerminated: '',
            dataRejected: '',
            totalPending : 0,
            totalRejected: 0,
            totalApproved: 0,
            totalTerminated: 0,

            request_device: 0,
            billing: [],
            billing_date: [],      
            isLoadingStatus: false,
            pageSize : 10,     
          }

    }

    componentDidMount() {
      this.callInitSmsApi();
    }

    callInitSmsApi = (search) => {
      this.callPendingSmsApi(search);
      this.callApprovedSmsApi(search);
      this.callTerminatedSmsApi(search);
      this.callRejectedSmsApi(search);
    }

    callPendingSmsApi = (search) => {
      let errorMessage = "";
      let status = 0;
      const { 
        REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = "sms_requests";
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');
     
      let param = {
        page: this.state.page,
        take: this.state.limit,
        sort: 'latest',
        status: "pending",
        start_date: "",
        end_date: "",
        application_company_id: "",
        search: search,
      };
      this.setState({
        isLoading:true
      }, () => {
        axios.get(
              URL,
              { 
                  params: param,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  }
              })
              .catch( error => {
                  try {
                      status = error.response.status;
                        if (error.response) {
                          // Request made and server responded
                          errorMessage = error.response.data;
                          apiUtil.toast(errorMessage, 'warning', 'error');
                        } else if (error.request) {
                          // The request was made but no response was received
                          errorMessage = error.request;
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          errorMessage = error.message;
                        }
                  } catch(err) {
                      errorMessage = 'Service Unavailable!'
                  }
                  this.setState({
                    isLoading:false
                  })
              }).then((response) => {
                if(response != null){
                this.setState({
                  dataPending: response.data.result,
                  totalPending: response.data.total,
                  isLoading: false,
                });
                // console.log("data pending", response.data);
                }
              });
      })
    }

    callApprovedSmsApi = (search) => {
      let errorMessage = "";
      let status = 0;
      const { 
        REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = "sms_requests";
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');
     
      let param = {
        page: this.state.page,
        take: this.state.limit,
        sort: 'latest',
        status: "approved",
        start_date: "",
        end_date: "",
        application_company_id: "",
        search: search,
      };
      this.setState({
        isLoading:true
      }, () => {
        axios.get(
              URL,
              { 
                  params: param,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  }
              })
              .catch( error => {
                  try {
                      status = error.response.status;
                        if (error.response) {
                          // Request made and server responded
                          errorMessage = error.response.data;
                          apiUtil.toast(errorMessage, 'warning', 'error');
                        } else if (error.request) {
                          // The request was made but no response was received
                          errorMessage = error.request;
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          errorMessage = error.message;
                        }
                  } catch(err) {
                      errorMessage = 'Service Unavailable!'
                  }
                  this.setState({
                    isLoading:false
                  })
              }).then((response) => {
                // console.log(response);
                if(response != null){
                this.setState({
                  dataApproved: response.data.result,
                  totalApproved: response.data.total,
                  isLoading: false,
                });
                // console.log("data Approved", response.data);
                }
              });
      })
    }

    callTerminatedSmsApi = (search) => {
      let errorMessage = "";
      let status = 0;
      const { 
        REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = "sms_requests";
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');
     
      let param = {
        page: this.state.page,
        take: this.state.limit,
        sort: 'latest',
        status: "terminated",
        start_date: "",
        end_date: "",
        application_company_id: "",
        search: search,
      };
      this.setState({
        isLoading:true
      }, () => {
        axios.get(
              URL,
              { 
                  params: param,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  }
              })
              .catch( error => {
                  try {
                      status = error.response.status;
                        if (error.response) {
                          // Request made and server responded
                          errorMessage = error.response.data;
                          apiUtil.toast(errorMessage, 'warning', 'error');
                        } else if (error.request) {
                          // The request was made but no response was received
                          errorMessage = error.request;
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          errorMessage = error.message;
                        }
                  } catch(err) {
                      errorMessage = 'Service Unavailable!'
                  }
                  this.setState({
                    isLoading:false
                  })
              }).then((response) => {
                // console.log(response);
                if(response != null){
                this.setState({
                  dataTerminated: response.data.result,
                  totalTerminated: response.data.total,
                  isLoading: false,
                });
                // console.log("data Terminated", response.data.result);
                }
              });
      })
    }

    callRejectedSmsApi = (search) => {
      let errorMessage = "";
      let status = 0;
      const { 
        REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = "sms_requests";
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');
     
      let param = {
        page: this.state.page,
        take: this.state.limit,
        sort: 'latest',
        status: "rejected",
        start_date: "",
        end_date: "",
        application_company_id: "",
        search: search,
      };
      this.setState({
        isLoading:true
      }, () => {
        axios.get(
              URL,
              { 
                  params: param,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token
                  }
              })
              .catch( error => {
                  try {
                      status = error.response.status;
                        if (error.response) {
                          // Request made and server responded
                          errorMessage = error.response.data;
                          apiUtil.toast(errorMessage, 'warning', 'error');
                        } else if (error.request) {
                          // The request was made but no response was received
                          errorMessage = error.request;
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          errorMessage = error.message;
                        }
                  } catch(err) {
                      errorMessage = 'Service Unavailable!'
                  }
                  this.setState({
                    isLoading:false
                  })
              }).then((response) => {
                // console.log(response);
                if(response != null){
                this.setState({
                  dataRejected: response.data.result,
                  totalRejected: response.data.total,
                  isLoading: false,
                });
                console.log("data rejected", response.data.result);
                }
              });
      })
    }

    callUpdateApi = (sms_id, sms_status, callback = null) => {

      let errorMessage = "";
      let id = sms_id;
      let status = sms_status;
      const {
        REACT_APP_AUTH_API_URL
      } = process.env;
      const PATH = 'admin/api/';
      const api = `sms_requests/${id}`;
      const param = {
       status : status
      };
  
      const URL = REACT_APP_AUTH_API_URL + PATH + api;
      let token = window.localStorage.getItem('access_token');
  
      axios.put(
        // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
        URL,
        param,
        {
          
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        })
        .catch(error => {
          try {
            status = error.response.status;
  
            if (error.response) {
              // Request made and server responded
              errorMessage = error.response.data;
              apiUtil.toast(errorMessage, 'warning', 'error');
            } else if (error.request) {
              // The request was made but no response was received
              errorMessage = error.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              errorMessage = error.message;
            }
          } catch (err) {
            errorMessage = 'Service Unavailable!'
          }
        }).then((response) => {
          if(callback) {
            callback()
          }
          this.callInitSmsApi();
        });
    }
    
    previewDialog = () => {
      return <CustomDialog 
        open={this.state.openPreviewDialog}
        title={'SMS Request History'}
        maxWidth={'lg'}
        padding={'0'}
        hideButtons={true}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
            openPreviewDialogItemName: null
          });
        }}
      >
       {this.customTableHistory()}
      </CustomDialog>
    }
    
    customTablePending = () => {

        return <Table 
        ref={this.refTable}
        className={'job-table-list' }  
        isLoading={this.state.isLoading}
        grouping={this.state.grouping}
        limit={this.state.limit}
        pageSize= {this.state.pageSize}
        page={this.state.page}
        total={this.state.totalPending}
        data={this.state.dataPending}
        // defaultExpanded={true}
        // selection={true}
        onPageChange={(page,size) => {
          this.state.limit = size;
          this.setState({
            page: page,
          }, () => { 
            this.callPendingSmsApi();
          });
        }}
        onSelectionChange={(rows)=>{
        //   this.setState({selectedRow:rows});
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
            { 
              title: "Company Name",
              field: "company_name",
              cellStyle: {
                minWidth: 200,
                maxWidth: 200            
              },
              render: (row, type) => {
                if(type === 'row' && row.application_company){ 
                  return <NavLink  
                  to={`/company-detail/${row.application_company && row.application_company.id}`}
                   
                  style={{ textDecoration: 'none' }}
                  >{row.application_company && row.application_company.company_name? row.application_company.company_name: ''}</NavLink>;
              } else {
                  return <></>;
                }
              }
            },
            { 
              title: "Requested By", 
              field: "request by",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150
              },
              render: (row, type) => {
                if(type === 'row'){
                return (row.requested_admin && row.requested_admin.first_name) ? <>{row.requested_admin.first_name} {row.requested_admin.last_name}</>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Request Date", 
              field: "request date",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  if(row.updated_at != ''){
                  let d = new Date(row.updated_at)
                  return d.toDateString();
                  }
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Status", 
              field: "status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.status_name ? <span style={{color: 'red'}}>{row.status_name }</span>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "History", 
              field: "history",
              cellStyle: {
                minWidth: 100,
                maxWidth: 100            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return  <Box pb={2}>
                  <CustomButton
                    className={'underline'}
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      this.setState({
                        openPreviewDialog: true,
                        historyPage: 1,
                        openPreviewDialogItem: row.sms_request_histories,
                        oldOpenPreviewDialogItem: row.sms_request_histories,
                        openPreviewDialogItemName: row.application_company.company_name,
                      });
                    }}
                  >
                    View
                  </CustomButton>
                </Box>
                } else {
                  return row;
                }
              }
            },
            { 
                title: "Action", 
                field: "action",
                cellStyle: {
                  minWidth: 220,
                  maxWidth: 220            
                },
                render: (row, type) => {
                  if(type === 'row'){
                    return <Box>
                     <Button 
                     className="btnApprove"
                     onClick={()=> {
                       this.callUpdateApi(row.id,"approved");
                       this.setState({
                        isLoading: true,
                       })
                     }}>Approve</Button>
                     <Button
                      className="btnReject"
                      onClick={()=> {
                        this.callUpdateApi(row.id,"rejected");
                        this.setState({
                          isLoading: true,
                         })
                      }}>Reject</Button>
                    </Box>;
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
      }
    
      customTableApprove = () => {
    
        return <Table 
        ref={this.refTable}
        className={'job-table-list' }  
        isLoading={this.state.isLoading}
        grouping={this.state.grouping}
        limit={this.state.limit}
        pageSize= {this.state.pageSize}
        // pageSizeOptions ={this.state.pageSizeOptions}
        page={this.state.page}
        total={this.state.totalApproved}
        data={this.state.dataApproved}
        // defaultExpanded={true}
        // selection={true}
        onPageChange={(page,size) => {
          
          this.state.limit = size;
          this.setState({
            page: page,
          }, () => { 
            this.callApprovedSmsApi();
          });
        }}
        onSelectionChange={(rows)=>{
        //   this.setState({selectedRow:rows});
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
          { 
            title: "Company Name",
            field: "company_name",
            cellStyle: {
              minWidth: 170,
              maxWidth: 170            
            },
            render: (row, type) => {
              if(type === 'row' && row.application_company){ 
                return <NavLink  
                to={`/company-detail/${row.application_company.id}`}
                 
                style={{ textDecoration: 'none' }}
                >{row.application_company && row.application_company.company_name? row.application_company.company_name: ''}</NavLink>;
            } else {
                return <></>;
              }
            }
          },
          { 
            title: "Requested By", 
            field: "request by",
            cellStyle: {
              minWidth: 140,
              maxWidth: 140
            },
            render: (row, type) => {
              if(type === 'row'){
              return row.requested_admin && row.requested_admin.first_name ? <>{row.requested_admin.first_name} {row.requested_admin.last_name}</>: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Request Date", 
            field: "request date",
            cellStyle: {
              minWidth: 160,
              maxWidth: 160            
            },
            render: (row, type) => {
              if(type === 'row'){
                if(row.updated_at != ''){
                  let d = new Date(row.updated_at)
                  return d.toDateString();
                  }
              } else {
                return row;
              }
            }
          },
            { 
              title: "Approved By", 
              field: "approved by",
              cellStyle: {
                minWidth: 140,
                maxWidth: 140            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.approved_admin && row.approved_admin.first_name? <>{row.approved_admin.first_name} {row.approved_admin.last_name}</>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Status", 
              field: "status",
              cellStyle: {
                minWidth: 140,
                maxWidth: 140            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.status_name ? <span style={{color: 'green'}}>{row.status_name }</span>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "History", 
              field: "history",
              cellStyle: {
                minWidth: 100,
                maxWidth: 100            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return  <Box pb={2}>
                  <CustomButton
                    className={'underline'}
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      this.setState({
                        openPreviewDialog: true,
                        historyPage: 1,
                        openPreviewDialogItem: row.sms_request_histories,
                        oldOpenPreviewDialogItem: row.sms_request_histories,
                        openPreviewDialogTotal : row.sms_request_histories.length,
                        openPreviewDialogItemName: row.application_company.company_name,
                      });
                    }}
                  >
                    View
                  </CustomButton>
                </Box>
                } else {
                  return row;
                }
              }
            },
            { 
                title: "Action", 
                field: "action",
                cellStyle: {
                  minWidth: 140,
                  maxWidth: 140            
                },
                render: (row, type) => {
                  if(type === 'row'){
                    return <Box>
                      <Button
                      className="btnTerminate"
                      onClick={()=> {
                        this.callUpdateApi(row.id,"terminated");
                        this.setState({
                          isLoading: true,
                         })
                      }}>Terminate</Button>
                       {/* <Button
                      className="btnReject"
                      onClick={()=> {
                        this.callUpdateApi(row.id,"Reject");
                        this.setState({
                          isLoading: true,
                         })
                      }}>Reject</Button> */}
                    </Box>;
                  } else {
                    return row;
                  }
                }
              },
          ]}
        />
      }

      customTableTerminated = () => {

        return <Table 
        ref={this.refTable}
        className={'job-table-list' }  
        isLoading={this.state.isLoading}
        grouping={this.state.grouping}
        limit={this.state.limit}
        pageSize= {this.state.pageSize}
        // pageSizeOptions ={this.state.pageSizeOptions}
        page={this.state.page}
        total={this.state.totalTerminated}
        data={this.state.dataTerminated}
        // defaultExpanded={true}
        // selection={true}
        onPageChange={(page,size) => {
          this.state.limit = size;
          this.setState({
            page: page,
          }, () => { 
            this.callTerminatedSmsApi();
          });
        }}
        onSelectionChange={(rows)=>{
        //   this.setState({selectedRow:rows});
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
          { 
            title: "Company Name",
            field: "company_name",
            cellStyle: {
              minWidth: 180,
              maxWidth: 180            
            },
            render: (row, type) => {
              if(type === 'row' && row.application_company){ 
                return <NavLink  
                to={`/company-detail/${row.application_company.id}`}
                 
                style={{ textDecoration: 'none' }}
                >{row.application_company && row.application_company.company_name? row.application_company.company_name: ''}</NavLink>;
            } else {
                return <></>;
              }
            }
          },
          { 
            title: "Requested By", 
            field: "request by",
            cellStyle: {
              minWidth: 180,
              maxWidth: 180
            },
            render: (row, type) => {
              if(type === 'row'){
              return row.requested_admin && row.requested_admin.first_name ? <>{row.requested_admin.first_name} {row.requested_admin.last_name}</>: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Request Date", 
            field: "request date",
            cellStyle: {
              minWidth: 150,
              maxWidth: 150            
            },
            render: (row, type) => {
              if(type === 'row'){
                if(row.updated_at != ''){
                  let d = new Date(row.updated_at)
                  return d.toDateString();
                  }
              } else {
                return row;
              }
            }
          },
            { 
              title: "Terminated By", 
              field: "terminated by",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.terminated_admin && row.terminated_admin.first_name? <>{row.terminated_admin.first_name} {row.terminated_admin.last_name}</>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Status", 
              field: "status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.status_name ? <span style={{color: 'orange'}}>{row.status_name }</span>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "History", 
              field: "history",
              cellStyle: {
                minWidth: 100,
                maxWidth: 100            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return  <Box pb={2}>
                  <CustomButton
                    className={'underline'}
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      this.setState({
                        openPreviewDialog: true,
                        historyPage: 1,
                        openPreviewDialogItem: row.sms_request_histories,
                        oldOpenPreviewDialogItem: row.sms_request_histories,
                        openPreviewDialogTotal : row.sms_request_histories.length,
                        openPreviewDialogItemName: row.application_company.company_name,
                      });
                    }}
                  >
                    View
                  </CustomButton>
                </Box>
                } else {
                  return row;
                }
              }
            },
            // { 
            //     title: "Action", 
            //     field: "action",
            //     cellStyle: {
            //       minWidth: 220,
            //       maxWidth: 220            
            //     },
            //     render: (row, type) => {
            //       if(type === 'row'){
            //         return <Box>
            //           {/* <Button
            //           className="btnTerminate"
            //           onClick={()=> {
            //             this.callUpdatePendingApi(row.id,"Terminate");
            //           }}>Terminate</Button> */}
            //            <Button
            //           className="btnReject"
            //           onClick={()=> {
            //             this.callUpdateApi(row.id,"rejected");
            //             this.setState({
            //               isLoading: true,
            //              })
            //           }}>Reject</Button>
            //         </Box>;
            //       } else {
            //         return row;
            //       }
            //     }
            //   },
          ]}
        />
      }
    
      customTableRejected = () => {
    
        return <Table 
        ref={this.refTable}
        className={'job-table-list' }  
        isLoading={this.state.isLoading}
        grouping={this.state.grouping}
        limit={this.state.limit}
        pageSize= {this.state.pageSize}
        // pageSizeOptions ={this.state.pageSizeOptions}
        page={this.state.page}
        total={this.state.totalRejected}
        data={this.state.dataRejected}
        // defaultExpanded={true}
        // selection={true}
        onPageChange={(page,size) => {
          
          this.state.limit = size;
          this.setState({
            page: page,
          }, () => { 
            this.callRejectedSmsApi();
          });
        }}
        onSelectionChange={(rows)=>{
        //   this.setState({selectedRow:rows});
        }}
        // Toolbar={this.customToolbar}
        // Row={this.customRow}
        columns={[
          { 
            title: "Company Name",
            field: "company_name",
            cellStyle: {
              minWidth: 180,
              maxWidth: 180            
            },
            render: (row, type) => {
              if(type === 'row' && row.application_company){ 
                return <NavLink  
                to={`/company-detail/${row.application_company.id}`}
                 
                style={{ textDecoration: 'none' }}
                >{row.application_company && row.application_company.company_name? row.application_company.company_name: ''}</NavLink>;
            } else {
                return <></>;
              }
            }
          },
          { 
            title: "Requested By", 
            field: "request by",
            cellStyle: {
              minWidth: 180,
              maxWidth: 180
            },
            render: (row, type) => {
              if(type === 'row'){
              return row.requested_admin && row.requested_admin.first_name ? <>{row.requested_admin.first_name} {row.requested_admin.last_name}</>: '';
              } else {
                return row;
              }
            }
          },
          { 
            title: "Request Date", 
            field: "request date",
            cellStyle: {
              minWidth: 150,
              maxWidth: 150            
            },
            render: (row, type) => {
              if(type === 'row'){
                if(row.created_at != ''){
                  let d = new Date(row.created_at)
                  return d.toDateString();
                  }
              } else {
                return row;
              }
            }
          },
            { 
              title: "Rejected By", 
              field: "rejected by",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.approved_admin.first_name? <>{row.approved_admin.first_name} {row.approved_admin.last_name}</>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "Status", 
              field: "status",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return row.status_name ? <span style={{color: 'grey'}}>{row.status_name }</span>: '';
                } else {
                  return row;
                }
              }
            },
            { 
              title: "History", 
              field: "history",
              cellStyle: {
                minWidth: 150,
                maxWidth: 150            
              },
              render: (row, type) => {
                if(type === 'row'){
                  return  <Box pb={2}>
                  <CustomButton
                    className={'underline'}
                    color={'primary'}
                    href={'/'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      this.setState({
                        openPreviewDialog: true,
                        historyPage: 1,
                        openPreviewDialogItem: row.sms_request_histories,
                        oldOpenPreviewDialogItem: row.sms_request_histories,
                        openPreviewDialogItemName: row.application_company.company_name,
                      });
                    }}
                  >
                    View
                  </CustomButton>
                </Box>
                } else {
                  return row;
                }
              }
            },
            // { 
            //     title: "Action", 
            //     field: "action",
            //     cellStyle: {
            //       minWidth: 220,
            //       maxWidth: 220            
            //     },
            //     render: (row, type) => {
            //       if(type === 'row'){
            //         return <Box>
            //           <Button
            //           className="btnTerminate"
            //           onClick={()=> {
            //             this.callUpdatePendingApi(row.id,"terminated");
            //           }}>Terminate</Button>
            //            <Button
            //           className="btnReject"
            //           onClick={()=> {
            //             this.callUpdatePendingApi(row.id,"rejected");
            //           }}>Reject</Button>
            //         </Box>;
            //       } else {
            //         return row;
            //       }
            //     }
            //   },
          ]}
        />
      }

      customTableHistory = () => {
        return <Table 
          ref={this.refTable}
          className={'job-table-list'}
          isLoading={this.state.isLoading}
          grouping={this.state.grouping}
          limit={this.state.historyLimit}
          paging={this.state.historyLimit}
          page={this.state.historyPage}
          total={this.state.openPreviewDialogTotal}
          data={this.state.openPreviewDialogItem}
          onPageChange={(page,limit) => {
              let data = this.state.oldOpenPreviewDialogItem.filter((obj,index) => index >= (this.state.historyLimit * (page - 1)) && index < (this.state.historyLimit * page));
              this.setState({
                openPreviewDialogItem: data,
                historyPage: page,
              });
          }}
          Toolbar={this.customToolbar}
          columns={[
            { 
              title: "Company Name", 
              field: "company name",
              render: (row) => {
                  return <Box textAlign={'center'}>
                    {this.state.openPreviewDialogItemName ? this.state.openPreviewDialogItemName : ''}
                  </Box>;
              }
          },
          { 
            title: "Status", 
            field: "status",
            render: (row) => {
              if (row.action == "Re-requested Request" || row.action == "Requested Request" || row.action == "Re-requested") {
                return <span style={{color: "orange"}}> Pending </span>;
              } else if (row.action == "Approved Request") {
                return <span style={{color: "green"}}> Approved </span>;
              } else if (row.action == "Terminated Request") {
                return <span style={{color: "red"}}> Terminated </span>;
              } else if (row.action == "Rejected Request") {
                return <span style={{color: "red"}}> Rejected </span>;
              }
                
            }
        },
            { 
              title: "Date", 
              field: "date",
              render: (row) => {
                if(row.created_at != ''){
                  let d = new Date(row.created_at)
                  return d.toDateString();
                  }
              }
          },
          { 
            title: "Action", 
            field: "action",
            render: (row) => {
                return row.action + " By";
            }
        },
        { 
          title: "Action By", 
          field: "action by",
          render: (row) => {
            return row.admin && row.admin.first_name ? <>{row.admin.first_name} {row.admin.last_name}</> : '';
          }
      },            
          ]}
        />
      }

    /* STICKY PAGE HEADER */
  customStickyPageHeader = () => {

    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container justify="flex-end">
        
        <Box clone>
          <Grid item xs={'auto'}>
       
            <CustomButton 
              type={'submit'}
              color={'secondary'}
              isLoading={this.state.isLoading}
            ><Box pl={2} pr={2}>Submit Form</Box>
            </CustomButton>
          
       
          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END STICKY PAGE HEADER */

  searchBox = () =>{
    return <Box mb={1}>
    <Grid container justify="flex-end">
    <Grid item xs={12} md={6} lg={6}>
    <CustomInput
              ref={this.refTableSearch}
              placeholder={'Search Company'}
              value={this.companySearch}
              onChange={(e) => {
                this.companySearch = e.target.value;
                this.callInitSmsApi(this.companySearch);
              }}
              endAdornment={
                <IconButton
                onClick={(e) => {
                  this.companySearch = e.target.value;
                  this.callInitSmsApi(this.companySearch);
                }}>
                  <SearchIcon />
                </IconButton>
              }
              onPressEnter={(e) => {
                this.companySearch = e.target.value;
                this.callInitSmsApi(this.companySearch);
              }}
            />
     </Grid> 
    </Grid>
    </Box>
  }

    render() {
        return <Box>
          <Card className={'job-listing-step'}>
      <Grid container spacing={2}>
      
          <Grid item xs={12} md={6} lg={6}>
            <GroupButton
              color={'primary'}
              selected={this.state.activeInactiveItem}
              buttons={[ 
                `Pending (${this.state.totalPending != 0? this.state.totalPending : '0' })`, 
                `Approved (${this.state.totalApproved != 0? this.state.totalApproved : '0'})`, 
                `Terminate (${this.state.totalTerminated != 0? this.state.totalTerminated : '0'})`, 
                `Rejected (${this.state.totalRejected != 0? this.state.totalRejected : '0'})`]}
              onClick={(selected, btn) => {
                this.setState({
                    activeInactiveItem : selected
                  });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
             {this.searchBox()}
        </Grid>
        </Grid>

        {(this.state.activeInactiveItem == 0) ? <>
          <Box>
            {this.customTablePending()}
            {this.previewDialog()}
          </Box>
        </> : ''}
        {this.state.activeInactiveItem == 1 ? <>
        {this.customTableApprove()}
        {this.previewDialog()}
        </> : ''}
        {this.state.activeInactiveItem == 2 ? <>
        {this.customTableTerminated()}
        {this.previewDialog()}
        </> : ''}
        {this.state.activeInactiveItem == 3 ? <>
        {this.customTableRejected()}
        {this.previewDialog()}
        </> : ''}
      </Card>
        </Box>
    }

    

}

export default RequestSMS;